/* 全体のスタイル */
body {
    font-family: 'Noto Sans JP', sans-serif; /* フォントを変更 */
    color: #333;
    margin: 0;
    padding: 0;
  }

.mobile-nav {
  display: none;
}


/* ヘッダー */
.header {
  background-color: rgba(0,0,0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}

.header .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff9f;
}

.header nav ul {
  list-style: none;
  display: flex;
  gap: 2rem;
}

.header nav ul li a {
    text-decoration: none;
    color: #ffffff9f; /* 文字を濃くする */
    transition: color 0.3s;
    font-size: small;
    font-weight: bold;
}

.header nav ul li a:hover {
  color: #3d3d3d;
}

/* ヒーローセクション */
.hero {
  position: relative;
  height: 100vh;
  display: flex;
  align-items:center;
  justify-content:flex-start;
  text-align: left;
  padding-left: 8rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.hero-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.hero-photo {
  display: none;
}

@media screen and (max-width: 767px) {
  .hero-video {
    display: none;
  }
  .hero-photo {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
}

.hero-content {
  position:relative;
  z-index: 1;
  text-align: left;
}

.hero-title {
  font-size: 2.5rem;
  font-weight: bold;
}

.hero-subtitle {
  font-size: 1.2rem;
  margin-top: 1rem;
}
.gothic {
  font-family: 'Meiryo', sans-serif;
}

/* ボタン */
.button {
  background-color: #db793c;
  color: white;
  font-size:0.8rem;
  font-weight: bold;
  padding: 12px 35px;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: none;
}

.button:hover {
  background-color: #3d3d3d;
}

/* セクション */
.section {
  padding: 4rem 2rem;
  background-color:transparent;
}

.section-title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
}

.section-content {
  max-width: 1200px; 
  margin: 0 auto;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.6;
}

/* サービスセクション */
.service-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 768px) {
  .service-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.service-card {
  background-color: #f3e6e2;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.service-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.service-description {
  margin-top: 1rem;
  font-size: 1rem;
}

/* ニュースセクション */
.news-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 768px) {
  .news-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.news-card {
  background-color: #f3e6e2;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.news-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.news-description {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.news-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
}

/* フッター */
footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1rem 0;
}



/* 動画背景のスタイル */
.video-background {
  position: relative;
  overflow: hidden;
}

.video-background .background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.video-background .section-content {
  position: relative;
  z-index: 1;
}

/* 背景動画のスタイル */
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.background-photo{
  display: none;
}

@media screen and (max-width: 767px) {
  .background-video {
    display: none;
  }
  .background-photo{
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
}

#about {
  color:white;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.9)); /* 背景色をグラデーションにする */
}


/* 問い合わせセクション */
.contact-info {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.contact-info p {
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

#members {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.5),rgba(255, 216, 195, 0.9)); /* 背景色をグラデーションにする */
}

/* メンバーセクション */
.members-grid {
  display: flex;
  /*  flex-direction: column;*/
  gap: 2rem;
  text-align: center;
  align-items: center;
  justify-items: center;
}

@media (max-width: 768px) {
  .members-grid {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
  }
}

.member-card {
  background-color: #ffffff;
  padding: 2rem;
  width: 400px;
  height: 500px;
  /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
  border-radius: 1%;
  text-align: center;
  align-items: center;
  }

.member-name {
  font-size: 1.5rem;
  font-weight: bold;
}
.member-info{
  justify-items: left;
}

.member-role {
  font-size: 1.25rem;
  margin-top: 0.5rem;
}

.member-description {
  margin-top: 1rem;
  font-size: 1rem;
}

.member-photo {
  width: 300px;
  height: 300px;
  border-radius: 1%;
  
}

/* 背景動画のスタイル */
.background-video2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  object-fit: cover;
  z-index: -2;
}

/* メンバーセクション */
.header-image-container {
  width: 100%;
  height: 30vh; /* ウィンドウの縦幅の30% */
  overflow: hidden;
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  background-color: rgba(0, 0, 0, 0); 
}



/* モバイル対応 */
@media (max-width: 767px) {
  .header .container {
    align-items: flex-start;
  }

  .header h1 {
    font-size: 1.2rem;
  }

  .header nav ul {
    flex-direction: column;
    gap: 0.3rem;
  }

  .hero {
    padding-left: 2rem;
    text-align: center;
    justify-content: center;
  }

  .hero-title {
    font-size: 1.8rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .section {
    padding: 2rem 1rem;
  }

  .section-title {
    font-size: 1.5rem;
  }

  .service-grid {
    grid-template-columns: 1fr;
  }

  .news-grid {
    grid-template-columns: 1fr;
  }

  .member-card {
    width: 100%;
    height: auto;
  }

  .member-photo {
    width: 100px;
    height: 100px;
  }
}



@media (max-width: 767px) {
  .header h1 {
    font-size: 1.2rem;
  }

  .desktop-nav {
    display: none;
  }

  .mobile-nav {
    display: flex;
    padding: top 50px;
    flex-direction: column;
    text-align: left;
    color: #000000;
  }

  .bm-burger-button {
    position: fixed;
    width: 30px;
    height: 30px;
    right: 20px;
    top: 30px;
    z-index: 1000;
  }

  .bm-burger-bars {
    background: #444444;
  }

  .bm-menu-wrap {
    position: fixed;
    right: 0; /* サイドバーを右端に固定 */
    top: 0;
    height: 100%;
  }
  .sidecolor {
    color: #4a4a4a;
    font-size: 1.3em;
  }

  .bm-menu {
    background-color: black;
    font-size: 2em;
    width: 100%; /* サイドバーの幅を調整 */
    padding-right: 0%; /* サイドバーを右端に固定 */
    padding-left: 25%;
    padding-top: 0%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0),rgba(255, 216, 195, 0.9)); /* 背景色をグラデーションにする */
  }
  .bm-cross-button {
    position: relative;
    width: 24px;
    height: 24px;
    right: 20px;
    top: 20px;
  }

  .bm-cross {
    background: #4a4a4a;
  }

    .bm-item-list {
      color: #000000;
      padding: 0.8em;
    }
  
    .bm-item {
      display: inline-block;
      text-decoration: none;
      margin-bottom: 10px;
      color: #000000;
      transition: color 0.2s;
    }
  
    .bm-item:hover {
      color: rgb(0, 0, 0);
    }
  
    .member-card {
      width: 100%;
      height: auto;
    }
  
    .member-photo {
      width: 100px;
      height: 100px;
    }
  }